import React, { useState } from "react";
import {
  ContactContainer,
  ContactWrapper,
  ContactP,
  ContactInput,
  ContactTextareaComment,
} from "./ContactElements";

import { ButtonEx } from "../ButtonElements";

const Contact = () => {
  const [nameReg, setNameReg] = useState("");
  const [companyReg, setCompanyReg] = useState("");
  const [emailReg, setEmailReg] = useState("");
  const [commentsReg, setCommentsReg] = useState("");


  return (
    <ContactContainer>
      <ContactWrapper className="py-5 px-5">
        <div className="my-auto">
          <h3 className="center-align mb-5">Contact Us</h3>
          <form onSubmit={e => {
              console.log(nameReg, ", ", companyReg, ", ", emailReg, ", ", commentsReg);
            }}>
            <ContactP>Name</ContactP>
            <ContactInput type="text" placeholder="Name" name='name' value={nameReg} onChange={e => setNameReg(e.target.value)} />
            <ContactP>Company</ContactP>
            <ContactInput type="text" placeholder="Company" name='company' value={companyReg} onChange={e => setCompanyReg(e.target.value)} />
            <ContactP>Email</ContactP>
            <ContactInput type="text" placeholder="Email" name='email' value={emailReg} onChange={e => setEmailReg(e.target.value)} />
            <ContactP>Comments</ContactP>
            <ContactTextareaComment type="text" placeholder="Tell us about your needs..." name='comments' value={commentsReg} onChange={e => setCommentsReg(e.target.value)} />
            <ButtonEx className="mt-5 mb-3">Submit</ButtonEx>
          </form>
        </div>
      </ContactWrapper>
    </ContactContainer>
  );
};

export default Contact;
