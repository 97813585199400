import styled from "styled-components";

export const ContactContainer = styled.div`
    height: 100vh;
    background: #000;
    align-content: center;
    justify-content: center;
    display: flex;
    position: relative;

`

export const ContactWrapper = styled.div`
    background: #fff;
    min-height: 600px;
    width: 500px;
    max-width: 90%;
    margin: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

`

export const ContactP = styled.p`
    font-weight: 600;
    margin-bottom: 5px;

`

export const ContactInput = styled.input`
    width: 100%;
    padding: 10px 10px;
    border-radius: 10px;
    border: solid gray 1px;
    margin-bottom: 30px;

`

export const ContactTextareaComment = styled.textarea`
    width: 100%;
    padding: 10px 10px;
    border-radius: 10px;
    border: solid gray 1px;
    margin-bottom: 30px;

`
  